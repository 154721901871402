// noinspection JSNonASCIINames,NonAsciiCharacters
export default {
    Planned: "Записаны",
    Arrived: "Ожидают",
    arrived: "Ожидают",
    Appointments: "Записи",
    Board: "Доска",
    List: "Список",
    Materials: "Материалы",
    Store: "Склад",
    Calendar: "Календарь",
    Customers: "Клиенты",
    customers: 'Клиенты',
    Patients: "Пациенты",
    Patient: "Пациент",
    Customer: "Клиент",
    Custom: "Пользовательский",
    Products: "Продукты",
    Product: "Продукт",
    Categories: "Категории",
    Category: "Категория",
    'Category name': 'Название категории',
    'Ex. Consumables': 'Расходники',
    Brands: "Бренды",
    Brand: "Бренд",
    Suppliers: "Поставщики",
    Supplier: "Поставщик",
    Subject: 'Тема',
    Body: 'Сообщение',
    'Appointment time': 'Время встречи',
    'Appointment date': 'Дата встречи',
    'Make default': 'Сделать по умолчанию',
    'Appointment notification template': 'Шаблон уведомлений о предстоящей записи',
    'Debt return notification template': 'Шаблон уведомлений должников',
    Services: "Услуги",
    'Services & prices': 'Услуги и прайс',
    Profile: "Профиль",
    'My profile': "Мой профиль",
    profile: "Профиль",
    Team: "Команда",
    "ICD-10": "МКБ-10",
    Reports: "Отчеты",
    Summary: "Общие",
    History: "История",
    Payments: "Оплаты",
    People: "Люди",
    'New customer': "Новый клиент",
    'New appointment': "Новая запись",
    Appointment: 'Запись',
    Confirmed: "Подтвержден",
    Late: "Опоздал",
    'Not confirmed': "Не подтвержден",
    confirmed: "Подтвержден",
    Completed: "Завершен",
    completed: "Завершен",
    Complete: "Завершить",
    'No show': "Не явился",
    'noshow': "Неявок",
    Today: 'Сегодня',
    Tomorrow: 'Завтра',
    'Next week': 'След. неделя',
    'Next month': 'След. месяц',
    Yesterday: 'Вчера',
    'This week': 'На этой неделе',
    'This month': 'В этом месяце',
    'Previous week': "Прошедшая неделя",
    'Previous month': "Прошлый месяц",
    'Custom range': "Указать период",
    'By date': "По дате",
    'Team member': "Сотрудник",
    'Team members': "Сотрудники",
    'By guarantee': 'По гарантии',
    guarantee: 'Гарантия',
    Guarantee: 'Гарантия',
    cash: "Наличные",
    credit: "В долг",
    card: "По карте",
    other: "Другое",
    userBalance: 'Баланс клиента',
    mobilePayment: 'Мобильные платежи',
    Presets: 'Шаблоны',
    Preset: 'Шаблон',
    'New balance': 'Баланс после',
    'Debt info': 'Возврат долга',
    'Preset has been updated': 'Шаблон сохранен',
    'Preset has been created': 'Шаблон добавлен',
    'Preset has been applied': 'Шаблон применен',
    bank: "Банковский п-д",
    Address: "Адрес",
    "First name": "Имя",
    "Last name": "Фамилия",
    "Middle name": "Отчество",
    "Patient first name": "Имя пациента",
    "Patient last name": "Фамилия пациента",
    "Patient middle name": "Отчество пациента",
    Details: "Детали",
    General: "Основные",
    Gender: "Пол",
    Male: "Мужчина",
    Female: "Женщина",
    male: "Мужчина",
    female: "Женщина",
    More: "Дополнительно",
    Birthdate: "Дата рождения",
    'Birth day': 'День рождения',
    Phone: 'Телефон',
    Complaints: 'Жалобы',
    'Complaints about': 'Жалобы на',
    Complaint: 'Жалоба',
    Objectively: "Объективно",
    Exam: "Осмотр",
    Exams: "Осмотры",
    'New exam': "Новый осмотр",
    'Customer card': 'Карточка клиента',
    'All exams history will be here': 'Вся история осмотров храниться в этом разделе',
    'Date created': 'Добавлен',
    'Customer passport': "Паспорт клиента",
    'Nothing yet': 'Пока ничего',
    Code: 'Код',
    Shortcode: 'Краткий  код',
    Description: 'Описание',
    Language: 'Язык',
    Label: "Наименование",
    manage: "Управление",
    'Complaints DB': 'Справочник жалоб',
    'Complaint has been saved': 'Жалоба сохранена',
    'Complaint has been created': 'Жалоба добавлена',
    'Objectively DB': 'Справочник объективно',
    References: "Справочники",
    Edit: "Редактировать",
    Timeline: "История",
    Notes: "Заметки",
    Note: "Заметка",
    Billing: "Оплаты",
    Storage: "Файлы",
    Search: "Поиск",
    Name: "Имя",
    Diagnosis: "Диагноз",
    Diagnoses: "Диагнозы",
    Treatment: "Лечение",
    Consumables: 'Расходники',
    Service: 'Услуга',
    Price: 'Цена',
    'Retail price': 'Розничная цена',
    'Retail price per': 'Розничная цена за',
    'Duration (Min)': 'Длительность (мин)',
    'Buffer time (Min)': 'Буферное время',
    'Ex.: A001': 'Напр.: A001',
    'Treatment name': 'Название процедуры',
    'Treatment duration': 'Длительность процедуры',
    'Time required to prepare to the next treatment': 'Время для подготовки к следующей процедуре',
    'Ex.: Primary consultation': 'Напр.: Первичная консультация специалиста',
    'Short description of treatment': 'Краткое описание процедуры',
    Cancel: 'Отмена',
    Save: 'Сохранить',
    Delete: 'Удалить',
    Ok: 'Готово',
    'Public treatment': "Отображать в календаре",
    'Public treatments will be published': "Процедура будет отображена у сотрудников, которые её проводят",
    'Services & treatments': 'Услуги и процедуры',
    'Loading...': 'Загрузка...',
    Create: 'Создать',
    Duration: 'Длительность',
    'New treatment': 'Новая процедура',
    min: 'мин.',
    minutes: 'мин.',
    h: 'ч.',
    Upcoming: 'Запланировано',
    Previous: 'Прошедшие',
    All: 'Все',
    New: 'Новый',
    cancelled: 'Отменен',
    Started: 'На приеме',
    started: 'На приеме',
    'Total scheduled': 'Всего визитов',
    'Start at': 'Начало в',
    'Appointment at': 'Запись на',
    Status: 'Статус',
    Staff: 'Сотрудник',
    Active: 'Активный',
    Archived: 'Архив',
    Age: 'Возраст',
    Image: 'Фото',
    Date: 'Дата',
    'No confirmed appointments': 'Нет подтвержденных записей',
    'No waiting appointments': 'Нет ожидающих записей',
    'No waiting online appointments': 'Нет ожидающих онлайн записей',
    'No started appointments': 'Нет начатых записей ',
    'In treatment': 'В процедуре',
    'Drag and drop to upload content!': 'Перетащите чтобы загрузить ',
    '...or click to select a file from your computer': '... или нажмите, чтобы найти на компьютере',
    '...or press CTRL + V to paste from clipboard': '... или нажмите CTRL + V, чтобы вставить из буфера',
    'Ex.:John': 'Прим.: Валентин',
    'Ex.:Doe': 'Прим.: Иванов',
    'Ex.:Kingsport': 'Прим.: Андреевич',
    'Select date': 'Выбрать дату',
    'Set photo': 'Указать фото',
    'Remove image': 'Удалить фото',
    'Blacklist customer': 'В черный список',
    Blacklisted: 'В черном списке',
    'Blacklisted users can not make new appointment. But administrator still can make an appointment.': 'Добавьте в черный список сомнительных или клиентов часто пропускающих процедуры',
    'Enable general SMS notifications': 'Включить общие СМС уведомления',
    'If active, the customer agreed to receive general SMS messages': 'Клиент будет получать уведомления о записях',
    'Enable promo SMS notifications': 'Включить промо нотификации',
    'If active, the customer agreed to receive promo SMS messages': 'Клиент будет получать сообщения о промо акциях и других событиях',
    Add: 'Добавить',
    'Add supply': 'Добавить поставку',
    'Add product': 'Добавить продукт',
    'Add category': 'Добавить категорию',
    'Add brand': 'Добавить бренд',
    'Add supplier': 'Добавить поставщика',
    'Ex.: Caries': 'Прим.: Кариес',
    Select: 'Выбрать',
    'Select diagnosis': 'Выбрать диагноз',
    'Preliminary diagnosis': 'Предварительный диагноз',
    'Final diagnosis': 'Окончательный диагноз',
    'Select from templates': 'Выбрать из шаблонов',
    'Save as template': 'Сохранить как шаблон',
    Use: 'Использовать',
    'Service name': 'Название услуги',
    'Ex.: Consultation': 'Прим.: Консультация',
    'Describe service': 'Опишите услугу',
    'Public section': 'Отображать в календаре',
    'Public sections will be published.': 'Услуга будет отображена у сотрудников, которые её проводят',
    Rank: 'Ранк',
    'Ex.: 0-10': 'Прим.: 0-10',
    Visits: 'Посещения',
    'No shows': 'Неявок',
    Options: 'Опции',
    Objectivly: 'Объективно',
    'Are you sure?': 'Вы уверены?',
    'Do you really want to delete the record ?': 'Вы подтверждаете удаление записи?',
    Specialty: 'Специальность',
    'Ex.: Therapist': 'Прим.: Терапевт',
    'Ex.: therapist': 'Прим.: therapist',
    Map: 'Карта',
    Missing: 'Отсутсвует',
    Root: 'Корень',
    Caries: 'Кариес',
    Pulpitis: 'Пульпит',
    Periodontitis: 'Периодонтит',
    Plombed: 'Пломба',
    Parodontoz: 'Пародонтоз',
    Shakiness: 'Подвижность',
    Crown: 'Коронка',
    Intact: 'Интактный',
    Depulped: 'Депульпированный',
    'Primary adentia': 'Первичная адентия',
    'Secondary adentia': 'Вторичная адентия',
    Implant: 'Имплант',
    'Cement caries': 'Кариес цемента',
    'Ortopedical status': 'Ортопедический',
    'Therapeutic status': 'Терапевтический',
    'Anchor pin': 'Анкерный штифт',
    Vinir: 'Винир',
    Retreat: 'Перелечить',
    Exclude: 'Удалить',
    Inset: 'Вкладка',
    Facet: 'Фасетка',
    'Parodontal status': 'Состояние пародонта',
    'Treatment required': 'Нуждается в лечении',
    'Treatment not required': 'Не нуждается в лечении',
    'Ex. Apple': 'Прим. Apple',
    Bundles: 'Пакеты',
    Bundle: 'Пакет',
    'Exam created': 'Новое обследование',
    Featured: 'В избранные',
    'Treatment plan': 'План лечения',
    'Select treatment': 'Выбрать лечение',
    'Add treatment': 'Добавить лечение',
    'Tooth #': 'Зуб #',
    'Total': 'Всего',
    'Root canals': 'Каналы',
    'Time': 'Время',
    'mSv': 'мкЗв',
    'No channel measurement': 'Каналы зубов еще не измерялись',
    'Includes services or products': 'Включает сервисы или продукты',
    'Print': 'Печать',
    Contract: 'Договор',
    Others: 'Другие',
    Agreement: 'Соглашение',
    'Add item': 'Дополнительные пункты',
    'No slots': 'Нет слотов',
    Dates: 'Даты',
    Intraoral: 'Интраоральный',
    intraoral: 'Интраоральный',
    Orthopantomogram: 'Ортопантомограмма',
    orthopantomogram: 'Ортопантомограмма',
    'CT scan': 'КТ',
    'cT scan': 'КТ',
    'Client application': 'TR-Client application',
    'Company EIN': 'Код по ОКПО',
    'Add service': 'Добавить сервис',
    'Add another appointment': 'Добавить новую дату',
    'service time': 'сервисное время',
    Xrays: 'Рентген',
    Xray: 'Рентген',
    'New xray': 'Новый рентген',
    Dose: 'Доза',
    Quantity: 'Количество',
    Type: 'Тип',
    'Record created': 'Запись добавлена',
    'Record updated': 'Запись обновлена',
    'Xray created': 'Добавлен рентген',
    'Invoice created': 'Выставлен инвойс',
    'Customer merged': 'Пациент объединен',
    'Ex.: Lc': 'Напр.: Lc',
    'Health card': 'Анкета здоровья',
    Stock: 'Склад',
    Supply: 'Поставка',
    Supplies: 'Поставки',
    Pricing: 'Цены',
    'Supply price': 'Цена поставки',
    'Enable product pricing': 'Управление ценой',
    'Enable stock management': 'Управление складом',
    'Enable supply management': 'Управление поставками',
    'Product name': 'Наименование продукта',
    'Ex.:Medical glove': 'Напр.:Перчатки медицинские',
    'Barcode': 'Баркод',
    'SKU': 'Артикул',
    'Ex.:12345678': 'Напр.: 12345678',
    'Ex.:AC12345': 'Напр.: AC12345',
    'Supply date': 'Дата поставки',
    'Expire date': 'Годен до',
    'Use current supply': 'Расходовать из этой поставки',
    'Supply quantity': 'Кол-во в поставке',
    'The supply will be forced to use in checkout and reports': 'Поставка будет использована как текущая, расходы будут списаны из этой поставки.',
    'Required minimum at store': 'Минимальное кол-во',
    'Measurement': 'Ед. Измерения',
    'Supplier details': 'Детали поставщика',
    'Supplier name': 'Имя поставщика',
    'Ex. Main supplier': 'Напр.:Основной поставщик',
    'Supplier notes': 'Заметки',
    'Ex.: Call after 10 AM': 'Напр.:Звонить после 10 утра',
    'Contacts': 'Контакты',
    'Contact name': 'Контактное лицо',
    'Ex. John Park': 'Напр.: Андрей Дибров',
    'Primary phone': 'Основной номер',
    'Secondary phone': 'Дополнительный',
    'Security': "Безопасность",
    'Schedule': "График",
    'Website': 'Вебсайт',
    'Street': 'Улица',
    'City': 'Город',
    'State': 'Район',
    'ZIP / Post code': 'Почтовый индекс',
    'Country': 'Страна',
    'Ex.: Tashkent': 'Напр. Ташкент',
    'Billing details': 'Данные биллинга',
    'Legal information': 'Юридические данные',
    'Legal information provided in this section will be used for all your invoices and other legal documents': 'Данные указанные в этом разделе будут использованы в юридических документах  а также счетах',
    'Legal name': 'Юридическое имя',
    'Enter notes for all invoices': 'Заметка для инвойсов',
    'Company timezone': 'Временная зона',
    'It is important to select correct timezone.': 'Важно выбрать правильную временную зону',
    'Interface default language': 'Язык интерфеса по умолчанию ',
    'Users can change in their profile': 'Пользователи системы могут сменить в настройках',
    'Currency': 'Валюта',
    'For all services calculations': 'Используется для всех  рассчетов',
    'Contact information': 'Контактная информация',
    'Social networks': 'Социальные сети',
    'Ml': 'Мл.',
    'ml': 'мл.',
    'Mg': 'Мг.',
    'mg': 'мг.',
    'Sm': 'См.',
    'sm': 'см.',
    'G': 'Грамм',
    'g': 'г.',
    'Units': 'Шт.',
    'units': 'шт.',
    Pack: 'Упаковка',
    'pack': 'Уп.',
    'Ex.:100': 'Напр.: 100',
    'Minimum': 'Минимум',
    'Per serving': 'Порция',
    'To buy': 'Закуп',
    'product': {
        'lowStock': '<b>{product.label}</b> заканчивается на складе'
    },
    'No new notifications': 'Нет новых уведомлений',
    'In waiting list': 'В лист ожидания',
    'Waitlist': 'Лист ожидания',
    'In buy list': 'В списке покупок',
    'Ex.: Agreement': 'Прим.:Договор',
    'Documents': 'Документы',
    'Company': 'Компания',
    'Title': 'Заголовок',
    'Content': 'Содержимое',
    'Source': 'Источник',
    'Sources': 'Источники',
    'Campaign': 'Кампания',
    'Referer': 'Пришел от:',
    'Requires value': 'Требуется значение',
    'Media': 'Фото',
    'Media manager': 'Управление медиа',
    'Treatments': 'Лечение',
    'Take a photo': 'Сделать фото',
    'Select Device': 'Выбрать устройство',
    'Logout': 'Выход',
    'Leave a comment': 'Оставить заметку',
    'Save note': 'Сохранить заметку',
    'Password': 'Пароль',
    'Current password': 'Текущий пароль',
    'New password': 'Новый пароль',
    'Confirm new password': 'Подвердите пароль',
    'Update password': 'Обновить пароль',
    'Password suggestions': 'Предложения по паролю',
    'To create a new strong password, you have to meet all of the following requirements:': 'Безопасный пароль должен соответсвовать следующим критериям:',
    'Minimum 8 character': 'Минимум 8 символов',
    'At least one special character (#%.,)': 'Минимум один спец символ  (#%.,)',
    'At least one number': 'Хотябы одна цифра',
    'Can’t be the same as a previous password': 'Не использовался ранее',
    'Update schedule': 'Обновить расписание',
    Monday: 'Понедельник',
    Tuesday: 'Вторник',
    Wednesday: 'Среда',
    Thursday: 'Четверг',
    Friday: 'Пятница',
    Saturday: 'Суббота',
    Sunday: 'Воскресенье',
    Notifications: 'Уведомления',
    'View all': 'Посмотреть все',
    'Search client': 'Поиск клиента',
    'Appointment schedule': 'Новая запись',
    Repeat: 'Повтор',
    Frequency: 'Частота',
    Ends: 'Заканчивается',
    'After {value} times': 'После {value}х раз',
    Repeats: 'Повторяется',
    'Every {value} days': 'Каждые {value} дня',
    'Every 5 days': 'Каждые 5 дней',
    Weekly: 'Раз в неделю',
    'Every {value} weeks': 'Каждые {value}  недели',
    Monthly: 'Раз в месяц',
    'Every {value} months': 'Каждые {value} месяцев',
    'Every 2 months': 'Каждые 2 месяца',
    'Every 3 months': 'Каждые 3 месяца',
    'Every 4 months': 'Каждые 4 месяца',
    'Month': 'Месяц',
    Yearly: 'Раз в год',
    'Do not repeat': 'Не повторять',
    Daily: 'Ежедневно',
    'Specific date': 'Дата',
    Ongoing: 'Все время',
    'View Appointment': 'Просмотр записи',
    Close: 'Закрыть',
    Checkout: 'Счет',
    'Payment method': 'Метод оплаты',
    'Payment amount': 'Сумма оплаты',
    'Amount': 'Сумма',
    'Balance': 'Баланс',
    'Payment successful': 'Оплата успешна',
    'Cancel appointment?': 'Отменить запись?',
    'Cancel appointment': 'Отменить запись',
    Socials: 'Социальные',
    Settings: 'Настройки',
    Builder: 'Конфигуратор',
    'General Details': 'Основные данные',
    'Company name': 'Имя компании',
    'Your company brand/known name.': 'Название вашего бренда/компании',
    'Ex.:ProfiDental': 'Напр.: ПрофиДентал',
    'Company description': 'Описание компании',
    'Describe your company in few words so no one wants to read long text.': 'Несколько слов о вашей компании',
    'Ex.: We are experts in dental care': 'Прим.: Мы эксперты в области зубной защиты',
    'Public company': 'Публичная компания',
    'Making your profile public means that anyone on the 32Desk network will be able to find you': 'Публичные компании показываются в каталогах 32Desk',
    'No payments yet': 'Пока нет оплат',
    'Access': 'Доступ',
    'Payment methods': 'Методы оплат',
    'Cash': 'Наличные',
    'Bank': 'Банковский перевод',
    'Card': 'По карте',
    'Credit': 'В долг',
    'Other': 'Другое',
    'Invoice': 'Счет',
    'Invoice #': 'Счет #',
    'Invoiced from': 'Счет выставлен от',
    'Invoiced ID': 'Счет Номер',
    'Invoiced to': 'Счет для',
    'Cost': 'Стоимость',
    'from': 'от',
    'Total amount due': 'Итого к оплате',
    'Set password': 'Задать новый пароль',
    'Username': 'Имя пользователя',
    'Role': 'Роль',
    Doctor: 'Доктор',
    Administrator: 'Администратор',
    Director: 'Директор',
    'Finance manager': 'Финансовый менеджер',
    'Item has been saved': 'Запись сохранена',
    'Item has been created': 'Запись добавлена',
    'Hide past': 'Скрыть прошедшие',
    Color: 'Цвет',
    Manipulations: 'Манипуляции',
    Manipulation: 'Манипуляция',
    'Ex.: Anesthesia': 'Напр.: Анестезия',
    'Select manipulations': 'Выбрать манипуляции',
    'Add manipulation': 'Добавить манипуляцию',
    'Please select a customer': 'Выберите пользователя',
    'Week': 'Неделя',
    'Day': 'День',
    'Days': 'Дни',
    'Hours': 'Часы',
    'Template': 'Шаблон',
    'Time templates': 'Шаблоны времени',
    'New template': 'Новый шаблон',
    'Add time template': 'Добавить шаблон времени',
    'Minutes': 'Минуты',
    '3 Days': '3 Дня',
    'mins ahead': ' | за минуту | за {n} минуты | за {n} минут',
    'hours ahead': ' | за {n} час | за {n} часа | за {n} часов',
    'days ahead': ' | за {n} день | за {n} дня | за {n} дней',
    'weeks ahead': ' | за {n} неделю | за {n} недели | за {n} недель',
    'months ahead': ' | за {n} месяц | за {n} месяца | за {n} месяцев',
    'in days': ' | через {n} день | через {n} дня | через {n} дней',
    'in weeks': ' | через {n} неделю | через {n} недели | через {n} недель',
    'in months': ' | через {n} месяц | через {n} месяца | через {n} месяцев',
    'Notify client': 'Уведомить клиента',
    'Note created': 'Заметка создана',
    Browse: 'Выбрать из списка',
    'Please select final diagnonsis': 'Выберите окончательный диагноз',
    'Sign in': 'Вход',
    'Access to your dashboard': 'Доступ в систему управления',
    'Forgot password?': 'Забыли пароль?',
    'Enter your password': 'Пароль вашего аккаунта',
    'Logging out': 'Выход',
    'See you soon': 'Ждем вас еще',
    'Done': 'Сделано',
    'User Activities': 'Лог действий',
    'Value': 'Значение',
    // 'N/A': '-',
    'Select diagnosis from ICD-10 list': 'Выберите диагноз из МКБ-10',
    'To Be Done': 'Нужно сделать',
    Action: 'Действие',
    'Add payment': 'Добавить оплату',
    'Cancellation reason. Ex.: Wrong payment': 'Причина отмены Прим. Ошибочная оплата',
    'Ex.: Treatment of caries': 'Прим. Лечение простого кариеса',
    'Payment cancellation': 'Отмена оплаты',
    'Payment cancelled': 'Оплата отменена',
    'Cancelled': 'Отменен',
    'Total paid': 'Всего оплачено',
    'Approved': 'Подтвержден',
    'Explanation': 'Описание',
    'User Balance': 'Баланс пользователя',
    'Debtors': 'Должники',
    'Outdated': 'Просрочено',
    'Tags': 'Тэги',
    'Payment status': 'Статус оплаты',
    'Payment from balance': 'Оплата со счета',
    'Debt details': 'Детали долга',
    'Total Payment': 'Всего к оплате',
    'Paid amount': 'Оплачено',
    'By gender': 'По полу',
    Income: 'Приход',
    'Invalid login': 'Неверный логин или пароль',
    'Average Bill': 'Средний счет',
    'Missing credentials': 'Введите логин и пароль',
    'New team member created': 'Новый пользователь сохранён',
    'Team member updated': 'Пользователь обновлен',
    'Team member deleted': 'Пользователь удалён успешно',
    Specialties: 'Специальности',
    'Static price': 'Фиксированная цена',
    'Static duration': 'Фиксированная длительность',
    'Allow log in': 'Разрешить авторизацию',
    'User balance': 'Баланс пользователя',
    'Your access has been restricted. Please contact your administrator to resolve this issue.': 'Ваш доступ был ограничен. Пожалуйства свежитесь с вашим администратором.',
    Roles: 'Роли',
    'Balance top up': 'Пополнение баланса',
    Resource: 'Ресурс',
    Permissions: 'Права доступа',
    'Paid partially': 'Оплачен не полностью',
    'Paid': 'Оплачен',
    'New role': 'Новая роль',
    View: 'Просматривать',
    'Edit role': 'Изменить роль',
    'new': 'Новый',
    'By payment type': 'По методу оплаты',
    'By team members': 'По членам команды',
    'By age': 'По возрасту',
    'Ex.:Sales manager': 'Прим. Менеджер по продажам',
    'Ex.:Can approve payments and manage billing': 'Прим. Может управлять финансами и складом',
    'my-team': 'Команда',
    'By day': 'По дням',
    'By status': 'По статусу',
    'Debt and prepaid': 'Долги и предоплаты',
    'my-company': 'Компания',
    'permissions': 'Права',
    'Most valuable team member': 'Наиболее ценный член команды',
    'Least valuable team member': 'Наименее ценный член команды',
    'Most valuable patient': 'Наиболее ценный пациент',
    'Least valuable patient': 'Наименее ценный пациент',
    'Most debt patient': 'Пациент с наибольшим долгом',
    'Most prepaid patient': 'Пациент с наибольшей предполатой',
    'Toppers': 'Топы',
    'Most selling product': 'Наиболее продаваемый продукт',
    'Least selling product': 'Наименее продаваемый продукт',
    'Most used product': 'Наиболее используемый продукт',
    'Most used service': 'Наиболее используемый сервис',
    'Most selling service': 'Наиболее продаваемый сервис',
    'Least used product': 'Наименее используемый продукт',
    'Least selling service': 'Наименее продаваемый сервис',
    'Least used service': 'Наименее используемый сервис',
    'roles': 'Роли',
    'users': 'Пользователи',
    'Add new role': 'Добавить роль',
    'Actions': 'Действия',
    'See all': 'Смотреть все',
    'Changes to roles saved': 'Изменения в ролях обновлены',
    'Appointment created': 'Запись создана',
    'Treatment presets': 'Шаблоны лечений',
    'SMS template': 'Шаблон SMS сообщения',
    'Telegram template': 'Шаблон Telegram сообщения',
    'Explanation of debt': 'Заметка',
    'Discount': 'Скидка',
    'Ex.: VIP': 'При.: VIP клиен',
    'Exam on': 'Осмотр от',
    'Done on': 'Сделано',
    'Per page': 'На страницу',
    'Pre-treatment status': 'Состояние зуба',
    'Teeth and mouth condition': 'Состояние зуба и полости рта',
    'Condition': 'Состояние',
    'Passport number': 'Номер паспорта',
    'Passport given by': 'Паспорт выдан',
    'Patient passport number': 'Номер паспорта пациента',
    'Patient age': 'Возраст пациента',
    'Patient gender': 'Пол пациента',
    'Patient diagnosis': 'Диагноз пациента',
    'Patient address': 'Адрес пациента',
    'Patient birth date': 'Дата рождения пациента',
    Earnings: 'Наработано',
    Revenue: 'Заработок',
    Payed: 'Оплачено',
    'Payed out': 'Выплачено',
    'Confirm': 'Подтвердить',
    Earned: 'Заработано',
    'Left over': 'Чистая прибыль',
    'Doctor first name': 'Имя доктора',
    'Doctor last name': 'Фамилия доктора',
    'Patient passport given by': 'Паспорт пацинета выдан',
    Prepaid: 'Предоплата',
    Channel: 'Канал',
    'Transaction ID': '№ Транзакции',
    'Tooth does not have any exams yet': 'Зуб еще не осматривался',
    'Start examing tooth by clicking "New exam"': 'Начните первый осмотр зуба прямо сейчас',
    'Conclusion': 'Заключение',
    'Application form': 'Медицинская карта пациента',
    Sum: 'Сумма',
    Debt: 'Долг',
    'Emergency logout': 'Экстренный выход',
    'Are you sure you want to logout all of your team?': 'Вы уверенны что хотить разлогинить всех в вашей команде?',
    perm: {
        'appointment': 'Записи',
        'customers': 'Клиенты',
        'my-company': 'Моя компания',
        'my-team': 'Моя команда',
        'cart': 'Корзина',
        'company': 'Компания',
        'profile': 'Профиль',
        'presets': 'Шаблоны лечения',
        'payments': 'Оплаты',
        'tags': 'Тэги',
        'communications': 'Связь с клиентом',
        'notes': 'Заметки',
        'xrays': 'Рентген',
        'products': 'Продукты и Склад',
        'reports': 'Отчеты',
        'notifications': 'Уведомления',
        'roles': 'Роли и права доступа',
        'treatment': 'Процедуры',
        'icd': 'МКБ-10',
        'odb': 'Объективно',
        'service': 'Услуги',
        'timeline': 'Лог действий',
        'bundle': 'Настройки',
        'time-templates': 'Шаблоны времени уведомлений',
        'force-logout': 'Экстренный выход',
        'tasks': 'Задачи',
        'work-place': 'Рабочие места',
        'service-reports': 'Отчеты по услугам',
        'product-reports': 'Отчеты по продуктам',
        'sales-reports': 'Отчеты по врачам',
        'customer-reports': 'Отчеты по врачам',
        'notification-reports': 'Отчеты по уведомлениям',
        'configurator': 'Конфигуратор',
        'treatment-plan': 'План лечения',
        'media-summary': 'Отчеты по файлам',
        'subscriptions': 'Подписки',
        'push-notifications': 'Push уведомления',
        'resources': 'Ресурсы',
        'text-templates': 'Шаблоны текста',
        'feedback-question': 'Вопросы опросников',
        'feedback-questionnaire': 'Опросы',
        'feedback-answers': 'Ответы на вопросы',
        'labs': 'Лабаротория',
        'expense': 'Расходы',
        'toppers': 'Топы',
        'balance-reports': 'Отчеты по предполатам/долгам',
        'payment-reports': 'Отчеты по методам оплат',
        'appointment-reports': 'Отчеты по записям',
        'suppliers': 'Поставщики',
        'supplies': 'Поставки',
        'categories': 'Категории',
        'brands': 'Бренды',
        'reports-summary': 'Общие отчеты',
        'invoices-summary': 'Отчет по счетам',
        'sources': 'Источники',
        'specialty': 'Специальности',
        'cdb': 'Жалобы',
        'notification': 'Уведомления',
        'my-notifications': 'Уведомления',
        'diagnose-objectively': 'Окончательный диагноз',
        'fields': 'Анкета здоровья',
        'exam': 'Осмотры',
        'manipulations': 'Манипуляции',
        'users': 'Пользователи',
        'permissions': 'Права доступа',
        'tooth': 'Зубы',
        'invoices': 'Счета',
        'transactions': 'Оплаты',
        'documents': 'Документы',
        'sms': 'СМС',
        'sessions': 'Активные сессии',
        'payment-history': 'Отчеты истории оплат',
        'expense-reports': 'Отчеты по расходам',
        'onlinepbx-incoming-call': 'Система онлайн-телефонии OnlinePBX',
        'customer-phone': 'Номер телефона пациента',
    },
    'Pay another': 'Оплатить еще',
    'Back': 'Назад',
    'Pay': 'Оплатить',
    'Payment': 'Оплата',
    'Pay back': 'Сдачи',
    'Ex.: Pain': 'Прим. Боль',
    'Invoice closed': 'Счет закрыт',
    'Baby map': 'Детская карта',
    'Treatment price': 'Цена процедуры',
    'My 32Desk': 'Мой 32Desk',
    'Current and previous illnes': 'Пересенные и сопутсвующие заболевания',
    'Profession': 'Профессия',
    'X-ray examinations history': 'История рентгенологических обследований',
    'Document generated by': 'Сгенерировано при помощи',
    'Manipulations made': 'Проделанные манипуляции',
    'Products used': 'Было использовано',
    'Ministry of Healthcare': 'Министерство здравоохранения',
    'ProfileFormBy': 'Медицинская документация форма № 043/У    <br />Утверждена Уз.РССВ 10.05.2010',
    'Medical card of dental patient': 'Медицинская карта стоматологического больного',
    'Fullname': 'Фамилия, Имя, Отчество',
    'Recommendations made': 'Даны рекомендации',
    'Recommendations': 'Рекомендации',
    'Ex. rinse mouth twice a day': 'Прим. Полоскать рот два раза в день',
    'Initial examination': 'Первичный осмотр',
    'Bite': 'Прикус',
    'Overjet': 'Глубокий',
    'Open bite': 'Открытый',
    'Crossbite': 'Перекресный',
    'Overbite': 'Дистальный (прогнатический)',
    'Underbite': 'Мезиальный (прогенический)',
    'Orthognathic': 'Ортогнатический',
    'Progenic': 'Прогенический',
    'Straight': 'Прямой',
    'Biprognatic': 'Бипрогнатический',
    'State of oral mucosa': 'Состояние слизистой  оболочки полости рта',
    'X-Ray and Lab examination results': 'Данные рентгеновских и лабораторных исследований',
    'Normal': 'Хороший',
    'Satisfactory': 'Удовлетворительный',
    'Unsatisfactory': 'Неудовлетворительный',
    'Bad': 'Плохой',
    'Very Bad': 'Очень плохой',
    'Accept appointments': 'Принимает записи',
    'Percent from total revenue': 'Базовый процент от услуг',
    'By Staff': 'По сотрудникам',
    'Scheduled for today': 'Записаны на сегодня',
    'Oral Hygiene Index': 'Индекс гигиены по Федорову-Володкиной',
    'Oral Hygiene Index GV': 'Индекс гигиены Грина-Вермиллиона',
    'Unknown': 'Неизвестный',
    'POS': 'Точка продаж',
    'POS terminal': 'Терминал продаж',
    'Duplicate to tooth': 'Скопировать на зуб',
    'All done': 'Все сделано',
    'Apply': 'Применить',
    'All top': 'Все верхние',
    'All bottom': 'Все нижние',
    'Clear': 'Очистить',
    'By tooth': 'По зубам',
    'Remove': 'Удалить',
    'Appointment has been rescheduled': 'Запись изменена',
    'Appointment has been created': 'Запись создана',
    'Appointment has been saved': 'Запись сохранена',
    'Do you confirm ?': 'Вы подтверждаете ?',
    'By source': 'По источнику',
    'Total earned': 'Выполнено на сумму',
    'Total debt for end of period': 'Общий долг на конец периода',
    'Debt for period of time': 'Долгов за этот период',
    'No treatment templates': 'Нет шаблонов лечения',
    'Try creating first by saving your current exam': 'Сохраните протоколы лечения в ваши шаблоны, чтобы не повторять действия',
    'In debt': 'В долг',
    'Edit/Reschedule appointment': 'Изменение записи',
    Yes: 'Да',
    No: 'Нет',
    'Name Last Middle name or Phone': 'Имя Фамилия Отчество или Телефон',
    'Phone number protect': 'Защита номеров телефонов',
    'Do not show customer phone numbers if user does not have a seeAll permission': 'Не показывать номер телефона клиента, если у пользователя нет разрешения',
    'Stock leftover': 'Остаток на складе',
    'Initial quantity': 'Изначальное количество',
    'Total revenue': 'Прибыль сотрудников',
    'Other ways of examination': 'Данные дополнительного обследования',
    'Objectivly details': 'Данные объективного осмотра',
    Diary: 'Дневник',
    'Remind after': 'Напомнить через',
    'Tasks': 'Задачи',
    'Reminder': 'Напоминание',
    'Start date': 'Начало выполнения',
    'Due date': 'Срок выполнения',
    'Select team member': 'Выберите члена команды',
    'Select service': 'Выберите услугу',
    'Remind about a treatment': 'Напомнить о процедуре',
    'Remind about': 'Напомнить о',
    'End before': 'Закончить до',
    'Start': 'Начать',
    'Select a treatment to be repeated': 'Выберите процедуру которую хотели бы повторить',
    'Edit/Reschedule task': 'Изминеть/Перенести задачу',
    'Create appointment': 'Создать встречу',
    'Treatment received': 'Полученная услуга',
    'Upcoming treatment': 'Предстоящяя услуга',
    'Do not call after 8 p.m.': 'Не звонить после 8 вечера',
    'Trash': 'Корзина',
    'New task created': 'Новая задача создана',
    'My templates': 'Мои шаблоны',
    'Recently used': 'Недавние',
    'Deleted at': 'Удален в',
    'Do you confirm': 'Вы подтвреждаете',
    'This operation is permanent and can not be reversed.': 'Данная операция не обратима.',
    'Permanently delete': 'Удалить навсегда',
    'Recover': 'Восстановить',
    'Recently deleted': 'Недавно удаленные',
    'Successfully recovered': 'Успешно востановлен',
    'Permanently deleted': 'Безвозвратно удален',
    'Item has been added': 'Услуга добавлена',
    'Duration step': 'Шаг длительности',
    'Calendar step': 'Шаг в календаре',
    'Appointment hours step': 'Шаг визитов',
    'Communications': 'Связь',
    'Incoming': 'Входящий',
    'Outgoing': 'Исходящий',
    'Outgoing call': 'Исходящий звонок',
    'Incoming call': 'Входящий звонок',
    'Write SMS': 'Написать SMS',
    'Write Email': 'Написать email',
    'Write Telegram Message': 'Написать в Telegram',
    'Payouts': 'Выплаты',
    'Changes saved': 'Изменения сохранены',
    'Error while saving': 'Ошибка при сохранении',
    'Invoice prefix': 'Префикс для инвойсов',
    'Invoice counter': 'Счетчик инвойсов',
    'View sample': 'Просмотреть пример',
    'Status localis': 'Развитие настоящего заболевания',
    'Objectivly checks': 'Объективно',
    'Exam has been created': 'Осмотр создан',
    'Exam has been saved': 'Осмотр сохранен',
    'Product has been saved': 'Продукт сохранен',
    'Product has been created': 'Продукт создан',
    'Roots has been saved': 'Данные сохранены',
    'Branch': 'Филиал',
    'Branches': 'Филиалы',
    'Branches can see all customers': 'Филиалы могут видеть всех клиентов',
    'Common customers, team and reports with branches': 'Общие клиенты, команда и отчеты с филиалами',
    'Select branch': 'Выберите филиал',
    'Start of the day': 'Сумма остатка с пред. рабочего дня',
    'End of the day': 'Сумма остатка на конец рабочего дня',
    'Date of operations': 'Дата кассовых операций',
    'Do you want to checkout?': 'Хотите выписать счет?',
    'Product has been added': 'Продукт добавлен',
    'Clear selection': 'Очистить выбор',
    'Grouped': 'Группировать',
    'Select tooth': 'Выбрать зубы',
    'Workplaces': 'Рабочие места',
    'Workplace': 'Рабочее место',
    'By workplaces': 'По рабочим местам',
    'New workplace': 'Новое рабочее место',
    'Workplace has been saved': 'Рабочее место было сохранено',
    'Workplace has been removed': 'Рабочее место удалено',
    'Teeth not selected': 'Зубы не выбраны',
    'Work order': 'Наряд',
    'Cart has been save': 'Корзина была сохранена',
    'Cart has been removed': 'Корзина была удалена',
    'Ex. Basic kit': 'Прим. Базовый набор',
    'No customer was selected': 'Клиент не был выбран',
    'Payout': 'Выплатить',
    'New Payout': 'Новая выплата',
    'Expenses': 'Расходы',
    'New expense': 'Новый расход',
    'Expense updated': 'Расход обновлен',
    'Expense added': 'Расход добавлен',
    'Send at appointment create': 'Отправлять при создании встречи',
    'Template successfully deleted': 'Шаблон успешно удален',
    'Successfully saved': 'Успешно сохранен',
    'Successfully removed': 'Успешно удален',
    'SMS notification is not enabled': 'SMS уведомление отключено',
    'Enable': 'Включить',
    'Bonus will not change User balance': 'Бонус не меняет баланс пользователя',
    'Bonus': 'Бонус',
    'PHONE_FORMAT': '+###(##) ###-##-##',
    'Phone format': 'Формат телефонного номера',
    'Stage': 'Этап',
    'Configurator': 'Конфигуратор',
    'Select preset': 'Выберите шаблон',
    'Movability': 'Подвижность',
    'Artificial tooth': 'Искусственный зуб',
    'Tooth missing': 'Зуб отсутствует',
    'Preview': 'Предварительный просмотр',
    'Attending doctor': 'Лечащий врач',
    'Select attending doctor': 'Выберите лечащего врача',
    'Teeth': 'Зубы',
    'Add to cart': 'Добавить в корзину',
    'Add stage': 'Добавить этап',
    'Dental plaque': 'Зубные отложения',
    'Remove stage': 'Удалить этап',
    'Added to cart': 'Добавленно в корзину',
    'Add tooth': 'Добавить зуб',
    'Group': 'Сгруппировать',
    'Add to work order': 'Добавить в наряд',
    'Save the initial examination': 'Сохранить первичный осмотр',
    'Save treatment plan': 'Сохранить план лечения',
    'Add subsistence': 'Добавить состояние',
    'Responsible': 'Ответственный',
    'Fittings': 'Примерки',
    Weak: "Слабо",
    Moderately: "Умеренно",
    Joined: "Объединенные",
    "Implant cemented": "Цементриуемые на импланте",
    Single: "Одиночные",
    "Screw fixed": "Винтовая фиксация",
    Reinforced: "Армированные",
    "System name": "Название системы",
    Diameter: "Диаметр",
    Cemented: "Цементируемые",
    'Kind of work': 'Вид работы',
    "abutment type": "тип абатмента",
    'Paint restoration': 'Окрасить реставрации',
    'no': 'нет',
    'weak': 'слабо',
    'moderately': 'умеренно',
    'Dissect teeth on the model': 'Отрепарировать зубы на модели',
    'In the missing tooth area': 'В области отсутсвующего зуба',
    'Form an ovoid impression on the model': 'Сформировать на модели овоидное вдавление',
    'joined': 'объединенные',
    'implant cemented': 'цементриуемые на импланте',
    'single': 'одиночные',
    'screw fixed': 'винтовая фиксация',
    'screw-fixed': 'винтовая фиксация',
    'reinforced': 'армированные',
    'Implant crowns': 'Коронки на имлантатах',
    'system name': 'название системы',
    'diameter': 'диаметр',
    'cemented': 'цементируемые',
    'Shoulder': 'Плечо',
    'Frame': 'Каркас',
    'Design of intermediate part': 'Дизайн промежуточной части',
    'Temporary restoration': 'Временная реставрация',
    'Select work': 'Выберите работу',
    'Work selection': 'Выбор работы',
    'Customer selection': 'Выбор пациента',
    'Next': 'Далее',
    'Append image': 'Добавить изображение',
    'New work': 'Новая работа',
    'Abutment type': 'Тип абатмента',
    'Reason for cancelling': 'Причина отмены',
    'Lab prefix': 'Префикс для нарядов',
    'Lab counter': 'Счетчик нарядов',
    'Labs': 'Лаборатория',
    'Fitted': 'Примеренный',
    'Print separately': 'Распечатать отдельно',
    'Appointments for period': 'Записей на этот период',
    'Appointments created for period': 'Записей создано за этот период',
    'Separate by color': 'Разделить по цвету',
    'y.o.': ' | {n} год | {n} года | {n} лет',
    'Preliminary treatment plan for': 'Предварительный план лечения для',
    'signature': 'подпись',
    'print plan note': 'С планом лечения ознакомлен и согласен. Мне понятен план, объем лечения, возможные осложнения на всех этапах лечения, возможность возникновения дополнительного обследования, изменение сроков лечения, дополнительной оплаты. Я получил ответы на все вопросы относительно предстоящего лечения и доверяю квалификации врачей. Цены действительны в течение 180 дней с момента проведенной консультации.',
    'remark': 'примечание',
    'full name': 'Ф.И.О.',
    'Mark as done': 'Отметить сделанным',
    'Generate and save plan': 'Сгенерировать и сохранить план лечения',
    'Elementary': 'Начальный',
    'No variations': 'Нет вариаций',
    'Treatment plan of': 'План лечения от',
    'For sending': 'К отправке',
    Wishes: 'Пожелания',
    'Shape and color': 'Форма и цвет',
    'Face shape': 'Форма лица',
    'When there is not enough space': 'При недостатке пространства',
    'The color of the crowns': 'Цвет коронки',
    'The color of the stump': 'Цвет культи',
    'The main color of the tooth': 'Основной цвет зуба',
    'The color of the gums': 'Цвет десны',
    Rectangular: 'Прямоугольная',
    Triangular: 'Треугольная',
    Oval: 'Овальная',
    'Grind the antagonist tooth': 'Пришлифовать зуб-антагонист',
    'Make a metal occlusal surface': 'Сделать металлическую окклюзионную поверхность',
    'Completion date': 'Дата сдачи',
    Vestib: 'Вестиб',
    Without: 'Без',
    'Print an image?': 'Печатать изображение?',
    Technician: 'Техник',
    'Search technician': 'Поиск Техника',
    Main: 'Основные',
    Additional: 'Дополнительные',
    'Spoons': 'Ложки',
    'Screws': 'Винты',
    'Abutments': 'Абатменты',
    'Implants': 'Импланты',
    'Transfers': 'Трансферы',
    'Analogues': 'Аналоги',
    'Straight abutment': 'Прямой абатмент',
    'Corner abutment': 'Угловой абатмент',
    'Cast abutment': 'Отливаемый абатмент',
    'Premill': 'Премилл',
    'Temporary abutment': 'Временный абатмент',
    'Temporary abutment on multiunit': 'Временный абатмент на мультиюнит',
    'Cast abutment on multiunit': 'Отливаемый абатмент на мультиюнит',
    'Accept': 'Принять',
    'Add treatment plan': 'Добавить план лечения',
    'Preliminary treatment plan': 'Предварительный план лечения',
    'Create entry': 'Создать запись',
    'Are you sure you want to accept a job?': 'Вы уверены, что хотите принять работу?',
    'Images': 'Изображения',
    'Archive': 'Архив',
    'Material consumption for the procedure': 'Расход материала на процедуру',
    'The cost of this material for the procedure': 'Себестоимость данного материала на процедуру',
    'Number of procedures per batch': 'Кол-во процедур за партию',
    'Profit from one procedure': 'Прибыль с одной процедуры',
    'Total profit': 'Общая прибыль',
    'Total profit of sale': 'Общая сумма реализации',
    'No results found': 'Результатов не найдено',
    'No options available': 'Нет доступных опций',
    'No sub-options': 'Нет дополнительных опций',
    'notConfirmed': 'Не подтвержден',
    'Last 12 month': 'Крайние 12 месяцев',
    'Your revenue': 'Ваша прибыль',
    'Nothing found': 'Ничего не найдено',
    'Profile has been updated': 'Профиль обновлен',
    'Appointments scheduled': 'Записей сделано на период',
    'Appointments created': 'Записей создано на период',
    'conditionCodes': {
        'C': 'C',
        'Pl': 'Pl',
        'D': 'Д',
        'P': 'P',
        'Pt': 'Pt',
        'Cr': 'К',
        'AT': 'И',
        'I': 'I',
        'R': 'R',
        'M': 'П',
        'DP': 'Н',
        'TM': 'О'
    },
    'Last visit': 'Последний визит',
    'Ex.: TV Adv.': 'Например: ТВ-реклама.',
    'Searching': 'Ищу',
    'Select a team member to checkout only his treatments': 'Выберите сотрудника для оплаты его проделанной работы',
    'Block The Time': 'Блокировать время',
    'Your account will expire on {date}. Please contact us.': 'Срок действия аккаунта истекает  {date}, пожалуйста свяжитесь с нами +998 97 006 32 32',
    'account_expired': "Срок действия аккаунта истек. Свяжитесь с нами <a href='tel:+998970063232'>+998 97 006 32 32</a>, telegram: <a href='https://t.me/Supp32Desk'>@Supp32Desk</a>",
    'Appointments saved': 'Записи сохранены',
    'All methods used': 'Все методы оплаты использованы',
    'Passwords do not match': 'Пароли не совпадают',
    'Current password is incorrect.': 'Введен неверный текущий пароль',
    'This field is required': 'Данное поле обязательное',
    'Password has been changed': 'Пароль был изменен',
    'Debt sum': 'Сумма долга',
    'Payout type': 'Вид расхода',
    'Office expenditures': 'Офисные расходы',
    'Overview': 'Обзор',
    'Change rate': 'Разница с прошлым периодом',
    'Total expenditure': 'Суммарные расходы',
    'Authentication timed out': 'Время ожидания соеденения истекло. Попробуйте еще раз',
    'Show Cancelled': 'Показать отмененные',
    'Cst': 'Стоим',
    'Qty': 'Кол',
    'Prod': "Прод",
    'Serv': "Услг",
    'Print receipt': 'Чек',
    'examDefaults': {
        'objectivly': 'Лицо симметричное, пропорциональное. кожа лица и видимые слизистые без видимых патологических изменений.',
        'OralMucosa': 'СОПР бледно- розового цвета, без видимых патологических изменений'
    },
    'New Update Available': 'Доступно новое обновление',
    'Click to refresh': 'Нажмите, чтобы обновить',
    'treatment-plan': {
        'label-placeholder': 'Прим.: Терапевтический план лечения'
    },
    'Document': 'Документ',
    'Filters': 'Фильтры',
    'Waiting List': 'Лист ожидания',
    'Task started': 'В процессе',
    'Show waiting list': 'Показать лист ожидания',
    'Add to waiting list': 'Добавить в лист ожидания',
    'Remove from waiting list': 'Убрать из листа ожидания',
    'Full Screen': 'На весь экран',
    'Menu': 'Меню',
    'phone-field': {
        'required': 'Обязательное поле'
    },
    'customer-field': {
        'required': 'Обязательное поле'
    },
    'invoice': {
        'print-preview': 'Предварительный счет',
        'preview-to': 'Предварительный счет для',
        'not-paid': 'Не оплачено',
        'summary': 'Общий счет',
    },
    'Сombine invoices': 'Объединить счета',
    'Merge': 'Объединить',
    'Oops.. Something Went Wrong..': 'Упс... Что-то пошло не так',
    'Union': 'Объединение',
    'Customers merge message': 'Объединение страниц пациентов является необратимым действием. На данную страницу будет скопирована вся информация с выбранной страницы. Выбранная страница будет перемещена во вкладку "Архив".',
    'Print templates': 'Шаблоны печати',
    'Treatment plan print page description': 'Информированное согласие с планом лечения',
    // TODO: 'Subscription' from SubscriptionList.vue,
    // TODO: 'Subscription' from SubscriptionList.vue,
    'In development': 'В разработке',
    'Field is empty': 'Поле пусто',
    'Recent Calls': 'Недавние звонки',
    'SMS Messages': 'SMS сообщения',
    'Email': 'Email',
    'Telegram': 'Телеграм',
    'CTRL+ENTER to send your message': 'CTRL + ENTER, чтобы отправить сообщение',
    'Type your message': 'Напишите ваше сообщение',
    'Total debt': 'Общий долг',
    'Enter phone number': 'Введите номер телефона',
    'Send code': 'Отправить код',
    'Telegram code': 'Telegram код',
    'Two-factor authentication': 'Двухфакторная авторизация',
    'payments': {
        'total-paid': 'Общее поступление',
        'total-services': 'Стоимость лечения',
        'total-discount': 'Сумма скидок',
    },
    'settingsDesc': {
        'services': 'Управляйте услугами, процедурами, их ценами и длительностью в этом разделе',
        'team': 'Информация о сотрудниках, цвет записей в календаре, график, зарплаты сотрудников настраиваются здесь',
        'company': 'Раздел по настройке вашей компании',
        'documents': 'Создавайте договоры и прочие документы с автоматическим заполнением имён клиентов и т.д. здесь',
        'sources': 'Как клиент узнал о вашей компании, позволяют анализировать рекламные кампании',
        'tags': 'Тэги позволяют сегментировать клиентов для дальнейшей фильтрации по ним, а также "маркировать" клиентов',
        'work-places': 'Добавляйте рабочие места, комнаты, кабинеты для удобного отображения в календаре',
        'roles': 'Гибкие настройки доступа- создавайте роли, указывая что может делать сотрудник',
        'user-activities': 'История проделанных действий по всем пользователям',
        'trash': 'Важные удаленные элементы попадают в корзину прежде чем удалиться навсегда',
        'specialties': 'Специальности сотрудников вашей компании',
        'icd10': 'Международная классификация болезней (МКБ-10)',
        'complaints': 'Перечень жалоб пациентов, позволит эффективно заполнять амбулаторные карты',
        'objectively': 'Перечень "Объективно..." для эффективного заполнения амбулаторных карт',
        'manipulations': 'Манипуляции, проводимые при процедурах позволяют автоматически заполнить амбулаторные карты',
        'presets': 'Шаблоны лечений- удобный инструмент для быстрой фиксации проделанных процедур, а также для автоматического заполнения амбулаторных карт',
        'configurator': 'Правильно настроенный конфигуратор позволит заполнить план лечения одним кликом',
        'questionnaires': 'Управляйте анкетами для опросов и сбора отзывов от клиентов',
        'textTemplates': 'Вы можете создавать, редактировать, удалять новые текстовые шаблоны',
        'sessions': 'Активные сессии и устройства',
    },
    'Telegram password': 'Telegram пароль',
    'Treatment select while scheduling an appointment in the calendar': 'Выбор процедуры в календаре при создании записи',
    'Enabled: treatment selection is required, and it will be included in the invoice': 'Вкл: в календаре нужно обязательно выбирать процедуру при создании записи и она будет добавлена в счет',
    'Disabled: treatment and service are not required, and they will be included in the invoice': 'Выкл: в календаре можно не выбирать услугу и процедуру и они не будут добавлены в счет',
    'Created by': 'Создал',
    'payment': {
        'status': {
            'debiting-from-balance': "Снятие с баланса"
        }
    },
    'Oral cavity': 'Полость рта',
    'communications': {
        'failed': 'Неотправленные',
        'scheduled': 'Запланированные',
        'sent': 'Отправленные'
    },
    'tasks': {
        'task-type': 'Тип задачи',
        'treatment-reminder': 'Напоминание о лечении',
        'user-reminder': 'Напоминание',
        'task-description': 'Описание задачи',
        'desc-placeholder': 'Позвонить бухгалтеру'
    },
    'treatment': {
        'print': 'Распечатать'
    },
    'Scale': 'Масштаб',
    'Show as a list': 'Показать списком',
    'Show online appointments': 'Показать онлайн записи',
    'Drafts': 'Черновики',
    'loadAppointment': 'Еще 1 запись | Еще {n} запись | Еще {n} записи | Еще {n} записей',
    'Technican': 'Техник',
    'sessions': {
        'sessions': 'Активные сессии',
        'last-action': 'Последнее действие',
        'user-agent': 'Браузер и девайс',
        'login': 'Последний вход',
        'ip': 'IP адрес'
    },
    'filters': {
        'by-completion-date': 'По дате сдачи',
    },
    'Could not fetch data': 'Не удалось получить данные',
    'Try again': 'Попробуйте снова',
    'Dental chart': 'Зубная формула',
    'For example: American, FDI': 'Например: американская, ВОЗ',
    'FDI': 'ВОЗ',
    'USA': 'Американская',
    'New initial exam layout': 'Новый вид первичного осмотра',
    'Use new initial exam layout': 'Использовать новый вид первичного осмотра',
    'Text Templates': 'Текстовые шаблоны',
    'Title of template': 'Название шаблона',
    'Content of template': 'Содержание шаблона',
    'Item has been removed': 'Запись удален',
    'English': 'Английский',
    'Russian': 'Русский',
    'Add Description': 'Добавить описание',
    'Describe treatment': 'Описать лечение',
    'x-ray': {
        'ct_up_low_jaw': 'КТ в/н/ч',
        'ct_up_low_tmj_jaw': 'КТ в/н/ч и суставов ВНЧС',
        'ct_up_ms_jaw': 'КТ в/ч и гайморовых пазух',
        'tgr_lateral_projection': 'ТРГ в боковой проекции',
        'tgr_front_projection': 'ТРГ в прямой проекции',
        'tgr_subchinoparietal_projection': 'ТРГ в проекции SMV',
        'section4_tmj': '4-х секционный снимок ВНЧС',
        'section6_tmj': '6-ти секционный снимок ВНЧС'
    },
    'Your session': 'Ваша сессия',
    'Online appointments': 'Онлайн записи',
    'Appointment booking': 'Запись',
    'Online appointment booking': 'Онлайн запись',
    'Allows your clients to record themselves using a step-by-step form': 'Позволяет вашим клиентам делать запись самостоятельно используя пошаговую форму',
    'Skip doctor select': 'Пропустить выбор врача',
    'Disallows your clients to select the doctor': 'Отключить возможность выбора врача',
    'Skip time select': 'Пропустить выбор времени',
    'Disallows your clients to select the time for appointment': 'Отключить возможность выбора времени приёма',
    'Skip date select': 'Пропустить выбор даты приёма',
    'Disallows your clients to select the date for appointment': 'Отключить возможность выбора даты приёма',
    'Skip service select': 'Пропустить выбор процедуры',
    'Disallows your clients to select the service': 'Отключить возможность выбора процедуры',
    'Mobile payment': 'Мобильные платежи',
    'Values': 'Значения',
    'Show If:': 'Показать, если:',
    'Is equal to:': 'Равен:',
    'Use in profile card:': 'Использовать в карточке профиля:',
    'Custom field': 'Пользовательское поле',
    'Subscribe Push Notification': 'Подпишитесь на push-уведомление',
    'Subscription': 'Push-уведомление',
    'No subscription yet': 'Подписки пока нет',
    'Current subscription': 'Текущая подписка',
    'Created at': 'Создано',
    'Patient health card': 'Анкета здоровья пациента',
    'The information you provide will allow the doctor to choose effective anesthesia, medications and methods of treatment, and correctly draw up a treatment plan. The doctor will explain (in justified cases): whether there is a connection between your general health and the state of the oral cavity.': 'Сообщенные вами сведения позволят врачу подобрать эффективную анестезию, лекарства и методы лечения, правильно составить план лечения. Врач пояснит (в оправданных случаях): есть ли связь между Вашим общим здоровьем и состоянием полости рта.',
    'Your data is a medical secret and will not be available to unauthorized persons.': 'Ваши данные составляют врачебную тайну и не будут доступны посторонним лицам.',
    'Last visit to a hospital or clinic (month, year):': 'Последнее посещение больницы или поликлиники (месяц, год):',
    'What was the appeal about?': 'По какому поводу было обращение?',
    'Are you registered in a psycho-neurological, narcological, dermatological and venereal dispensary?': 'Состоите ли вы на учете в психоневрологичесом, наркологическом, кожно-венерологических диспансерх?',
    'If yes, in what and from what time?': 'Если да, то в каком и с какого времени?',
    'In addition, I want to inform you about the state of my health the following:': 'Дополнительно хочу сообщить о состоянии своего здоровья следующее:',
    'Date of visit': 'Дата визита',
    'Telegram authentication': 'Вход в Telegram аккаунт',
    'Enter the phone number to enter the Telegram account from which notifications about recordings will be sent to customers': 'Введите номер телефона для входа в аккаунт Telegram, с которого клиентам будут отправляться оповещения о записях',
    'Integrations': 'Интеграции',
    'Soon': 'Скоро',
    'Current list': 'Текущий список',
    'Total size': 'Общий размер',
    'Now': 'Сейчас',
    'When scheduled': 'При создании',
    'Upload date': 'Дата загрузки',
    'Size': 'Размер',
    'Ascending': 'По возрастанию',
    'Descending': 'По убыванию',
    'Sort by': 'Сортировать по',
    'Ex.: Pain while eating': 'Пример: Боль во время пищи',
    'Already exists': 'Уже существует',
    'Logged in': 'Выполнен вход',
    'Log out': 'Выйти',
    'Phone number': 'Номер телефона',
    'Log in': 'Войти',
    'Enter the code from SMS or check your telegram account': 'Введите код из SMS или проверьте Ваш Telegram аккаунт',
    'You have enabled cloud password. Please, enter it here': 'У вас включён облачный пароль. Пожалуйста, введите его здесь',
    'Invalid phone number': 'Неверный номер телефона',
    'Invalid code': 'Неверный код',
    'Invalid password': 'Неверный пароль',
    'Too many attempts. Try again later': 'Слишком много попыток. Попробуйте позже',
    'Telegram integration is not configured': 'Интеграция с Telegram не настроена',
    'Configure': 'Настроить',
    'Scheduled': 'Запланированное',
    'Do not notify': 'Не уведомлять',
    'Add Note': 'Добавить заметку',
    'Select country': 'Выберите страну',
    'For example: USA, Finland, etc': 'Например: Узбекистан, Россия и т.д.',
    'Wrong API key': 'Неверный API ключ',
    'OnlinePBX API key': 'API ключ OnlinePBX',
    'OnlinePBX domain': 'Домен OnlinePBX',
    'OnlinePBX VoIP service integration': 'Интеграция VoIP сервиса OnlinePBX',
    'Integration saved successfully': 'Интеграция осущствлена успешно',
    'Logged out successfully': 'Выход осущствлён',
    'Domain should end with .onpbx.ru': 'Домен должен оканичиваться на .onpbx.ru',
    'OnlinePBX is not configured': 'Интеграция с OnlinePBX не настроена',
    'Waiting clinic to accept the call': 'Ждём приём вызова со стороны клиники',
    'Clinic has not accepted call': 'Клиника не приняла вызов',
    'Call is started': 'Вызов активен',
    'Call': 'Вызов',
    'Current date': 'Текущая дата',
    'Added successfully': 'Добавлено успешно',
    'Customers with uncompleted work order': 'Пациенты с незавершённым нарядом',
    'Edit treatment data after work order': 'Редактирование медицинской карты',
    'Show additional page on work order to edit done treatments\' data': 'Показать дополнительную страницу для заполнения анкеты',
    'No records for this call': 'Для этого вызова нет записей',
    'Show teeth in invoice': 'Отображать зубы в счетах (инвойсах)',
    'Show \'Tooth\' column in invoice': 'В инвойсе будут показаны вылеченные зубы',
    'Legend': 'Условные обозначения',
    'DP - dental plaque': 'Н - зубные отложения',
    'M - movability': 'П - подвижность',
    'R - root': 'R - корень',
    'C - caries': 'C - кариес',
    'AT - artificial tooth': 'И - искусственный зуб',
    'Cr - crown': 'К - коронка',
    'Pt - periodontitis': 'Pt - периодонтит',
    'P - pulpitis': 'P - пульпит',
    'D - depulped': 'Д - депульпированный',
    'Pl - plombed': 'Pl - пломба',
    'I - implant': 'I - имплант',
    'Customer is archived': 'Пациент перенесён в архив',
    'Customer is updated': 'Пациент обновлён',
    'Customer is registered': 'Пациент зарегистрирован',
    'Note has been deleted': 'Заметка удалена',
    'Print options': 'Параметры печати',
    'Hide teeth map': 'Скрыть карту зубов',
    'Hide remark': 'Скрыть примечание',
    'currentStep': 'Шаг {currentStep} из {totalSteps}',
    'Set account password': 'Установить пароль для аккаунта',
    'Treatment templates': 'Шаблоны лечений',
    'Complains / Objectives': 'Жалобы / Объективно',
    'Import templates': 'Импортировать',
    'Setup finished': 'Установка завершена',
    'Size of teeth map': 'Размер карты зубов',
    'Adult map': 'Взрослая карта',
    'Add section': 'Добавить блок',
    'Ex.: Registration questionnaire': 'Пример: Опросник при регистариции',
    'Show in health card': 'Отображать в карте здоровья',
    'Title of section': 'Название блока',
    'email.subject': 'Сообщение от {company}',
    'Treatment has been deleted': 'Процедура удалена',
    'Manipulation was removed': 'Манипуляция была удалена',
    'Service was removed': 'Услуга была удалена',
    'Product was removed': 'Продукт был удалён',
    'Changed': 'Изменение',
    'Interest': 'Ставка',
    'Company code': 'Код предприятия',
    'Code will be used in profile card of customer': 'Код будет использован в медицинской карте пациента',
    "The description of the treatment in the patient's medical record is not editable": "Описание лечения в медицинской карте пациента не редактируется",
    'The order includes an additional treatment description page for the patient\'s medical record \'Next\'. The outfit will be considered incomplete if you do not go to this additional page.': "В Наряде включена дополнительная страница описания лечения для медицинской карты пациента \"Далее\". Наряд будет считаться незавершенным, если не перейти на эту дополнительную страницу.",
    'Top ups': 'Пополнения баланса',
    'Date constraint is required': 'Фильтр по дате обязателен',
    'Deleted customer': 'Удалённый клиент',
    'Service language': 'Язык обслуживания',
    'Default service language': 'Язык обслуживания по умолчанию',
    'Default service language for new customer': 'Язык обслуживания для новых клиентов',
    'Debt payment': 'Погашение задолженности',
    'Removed user': 'Удалённый пользователь',
    'Language & Region': 'Язык и регион',
    'My earnings': 'Мой заработок',
    'Last withdrawal': 'Последняя выплата',
    'Code has been sent to the number:': 'Код отправлен на номер:',
    'Change number': 'Изменить номер',
    'Help': 'Помощь',
    'Notification templates': 'Шаблоны уведомлений',
    'Service language allows you to send notifications in the client\'s language': 'Язык обслуживания позволяет отправлять уведомления на языке клиента',
    'Service languages': 'Языки обслуживания',
    'Add a new service language': 'Добавить новый язык обслуживания',
    'Default': 'По-умолчанию',
    'Language added': 'Язык добавлен',
    'Language removed': 'Язык удалён',
    'New default language selected': 'Язык по-умолчанию обновлён',
    'Appointment reminder notification': 'Уведомления о записи',
    'Notifications about upcoming appointments': 'Уведомление пациента о предстоящей записи в календаре',
    'Appointment confirmation notification': 'Уведомления о подтверждении записи',
    'Notifications about confirmation of online bookings': 'Уведомление пациента о подтверждении онлайн-записи в календаре',
    'Debt return notification': 'Уведомления о долге',
    'Notifications about return of debt': 'Уведомление пациента о наличии долга',
    'Debt due date': 'Дата возврата долга',
    'Appointment rescheduled notifications': 'Уведомления о переносе записи',
    'Notification about reschedule of the appointment': 'Уведомление пациента об изменении даты или времени записи',
    'Appointment rescheduled notification template': 'Шаблон уведомлений об изменении времени записи',
    'Appointment confirmed notification template': 'Шаблон уведомлений о подтверждении записи',
    'VAT': 'НДС',
    'incl. VAT': 'в т.ч. НДС',
    'with VAT': 'с НДС',
    'Prices include VAT': 'Цены указаны с учетом НДС',
    'Social Links': 'Социальные ссылки',
    'Questionnaires': 'Опросы',
    'questionnaire': {
        'questions_count': 'Вопросов: {n}',
    },
    'questionnaire-question': {
        'types': {
            'text': 'Текст',
            'single-choice': 'Один вариант',
            'multiple-choice': 'Несколько вариантов',
        }
    },
    'No options yet. Press + to add': 'Пока нет вариантов. Нажмите + чтобы добавить',
    'Option': 'Вариант',
    'Option. Ex. Satisfied': 'Например, Очень хорошо',
    'New question': 'Новый вопрос',
    'Edit question': 'Редактировать вопрос',
    'New questionnaire': 'Новый опрос',
    'Edit questionnaire': 'Редактировать опрос',
    'Questions count': 'Количество вопросов',
    "Question has been created": 'Вопрос добавлен',
    'Question has been saved': 'Вопрос сохранён',
    'Question deleted successfully': 'Вопрос удалён',
    'Questionnaire has been created': 'Опрос создан',
    'Questionnaire has been saved': 'Опрос сохранён',
    'Questionnaire has been removed': 'Опрос удалён',
    'No questions yet': 'Пока нет вопросов',
    'Question': 'Вопрос',
    'Ex.: Rate the service': 'Например: Как вы оцениваете обслуживание?',
    'Answer type': 'Способ ответа',
    'Select type': 'Выберите способ',
    'Send survey link': 'Отправить ссылку на опрос',
    'Copied to clipboard!': 'Скопировано в буфер обмена',
    'Surveys': 'Опросы',
    'Answers': 'Ответы',
    'answers': 'ответы',
    'questions': 'вопросов',
    'No questionnaires': 'Пока нет опросов',
    'Ex.: Service rate survey': 'Например, Анкета пациента',
    'To': 'Кому',
    'No treatment': 'Без услуги',
    'Not paid': 'Не оплачен',
    'Total received': 'Всего поступило',
    'Notification channels': 'Каналы уведомлений',
    'Default notification channels': 'Каналы уведомлений по-умолчанию',
    'These channels will be enabled for new customers': 'Эти каналы будут включены для новых пациентов',
    'WhatsApp integration': 'Интеграция с WhatsApp',
    'Phone number ID': 'ID номера телефона',
    'Access token': 'Токен доступа',
    'WhatsApp webhook setup params:': 'Параметры для вебхука WhatsApp:',
    'Verify Token': 'Токен валидации',
    'Whatsapp integration updated': 'Интеграция с WhatsApp обновлена',
    'Webhook URL': 'URL вебхука',
    'Whatsapp integration cleared': 'Интеграция с WhatsApp удалена',
    'Enter Whatsapp template name': 'Введите имя шаблона WhatsApp',
    'Link for Online booking': 'Ссылка для Онлайн записи',
    'alias: value already exists.': 'Этот псевдоним уже занят',
    'Enter account data': 'Введите данные для аккаунта',
    'Confirm your email': 'Подтвердите ваш e-mail',
    'Error': 'Ошибка',
    'Error occurred. Try again later': 'Возникла ошибка. Попробуйте позже',
    'Email is already registered.': 'Этот email уже используется.',
    'Company name is required': 'Название компании обязательно',
    'Phone is required': 'Телефон обязателен',
    'Invalid email': 'Неверный email',
    'Email is required': 'E-mail обязателен',
    'Last name is required': 'Фамилия обязательна',
    'First name is required': 'Имя обязательно',
    'We sent a link to verify your email': 'Мы отправили ссылку для подтверждения вашего email',
    'Account created. You can login now': 'Учётная запись готова. Войдите в систему используя ваш email и новый пароль',
    'Scheduled to': 'Запланировано на',
    'Reset link sent to your email': 'Ссылка для восстановления пароля отправлена на вашу почту',
    'Reset Password': 'Восстановить пароль',
    'Enter your email to get a password reset link': 'Введите email, который был использован при регистрации в 32desk',
    'Password reset': 'Восстановление пароля',
    'Enter new password': 'Введите новый пароль',
    'Remember your password?': 'Вспомнили пароль?',
    'If your email would be found in 32Desk, you will receive instruction to reset password': 'Если ваш email найден в системе, вы получите инструцию по восстановлению аккаунта',
    'Error occurred': 'Произошла ошибка',
    'Go to login': 'Войти',
    'Email is sent': 'Email отправлен',
    'Please, try again': 'Пожалуйста, попробуйте ещё раз',
    'Password is updated': 'Пароль изменён',
    'You can login with new password to 32Desk': 'Вы можете войти с новым паролем в 32Desk',
    'Please wait, this will take a few seconds': 'Подождите, это займет несколько секунд',
    'Retry': 'Попробовать снова',
    'Terminate other sessions': 'Завершить все сессии',
    'Logs out all devices except for this one': 'Выполняет выход из всех устройств, кроме текущего',
    'Terminate all sessions, except current one?': 'Завершить все сессии, кроме текущей?',
    'Sign in with Google': 'Войти через Google',
    'google-auth.account-not-found-message': 'Аккаунт с адресом {email} не найден. Вы хотите <a class="alert-link text-decoration-underline" href="/welcome">зарегистрироваться</a>?',
    'Share calendar': 'Поделиться календарём',
    'Invoices': 'Счета',
    'Telegram user is not found': 'Телеграм аккаунт пациента не найден',
    'Search telegram contact': 'Найти телеграм контакт',
    'No telegram account was attached to this customer': 'Телеграм аккаунт не прикреплён к этому пациенту',
    'Telegram user found and saved': 'Телеграм аккаунт пациента найден и сохранён',
    'Telegram user not found for customer': 'Пользователь телеграм не найден',
    'New link': 'Новая ссылка',
    'Publish': 'Опубликовать',
    'Bio': 'О себе',
    'Link': 'Ссылка',
    'Profile data has been updated': 'Данные профиля обновлены',
    "Display name": "Отображаемое имя",
    "Contact Links": "Контактные ссылки",
    'Without service': 'Без услуги',
    'No services': 'Нет услуг',
    "Hide online booking link": "Скрыть ссылку на онлайн-бронирование",
    "Background": "Фоновое изображение",
    "Theme": "Тема",
    "Book an appointment": "Записаться на прием",
    "Invalid link. Please use only letters, numbers, dots and underscores": "Неверная ссылка. Используйте только латинские буквы, цифры, точки и нижние подчёркивания",
    "Never": "Никогда",
    "Last contact syncronization": "Последняя сихронизация контактов",
    "Sync contacts": "Синхронизировать контакты",
};
